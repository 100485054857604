import {
  ConfirmEmailParams,
  ForgotPwdParams,
  LoginUserParams,
  MfaVerificationParams,
  MfaConfigurationParams,
  RegisterUserParams,
  ResentEmailVerificationParams,
  ResetPasswordParams
} from './apiParamsTypes';
import CONFIG from '../../../config';
import { ApiError, classifyAndThrowError, ErrorType } from '../ApiError';
import axios from 'axios';

export const registerUser = async (payload: RegisterUserParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'registration'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const refreshToken = async (payload: LoginUserParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'refreshToken'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: any) {
    classifyAndThrowError(error);
  }
};

export const loginUser = async (payload: LoginUserParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'login'
      },
      data: payload,
      headers: {
        'X-User-Identifier': payload.userData.email
      }
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: any) {
    classifyAndThrowError(error);
  }
};

export const verifyMfaCode = async (payload: MfaVerificationParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'mfaVerification'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const updateMfaSettings = async (payload: MfaConfigurationParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'configureMfaSettings'
      },
      data: payload
    });
    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const forgotPassword = async (payload: ForgotPwdParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'forgotPassword'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const resetPassword = async (payload: ResetPasswordParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'updatePasswordWithCode'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const confirmRegistration = async (payload: ConfirmEmailParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'emailVerification'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const resendEmailVerification = async (payload: ResentEmailVerificationParams) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'resendEmailVerification'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const newPasswordRequiredChallenge = async (payload: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'newPasswordRequired'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};

export const adminResetPasswordChallenge = async (payload: any) => {
  try {
    const response = await axios({
      method: 'post',
      url: CONFIG.openApiEndpoint + '/userAuthorization',
      params: {
        action: 'adminResetPassword'
      },
      data: payload
    });

    switch (response.status) {
      case 200:
        return response;
      default:
        throw new ApiError('Error', ErrorType.SERVER);
    }
  } catch (error: unknown) {
    classifyAndThrowError(error);
  }
};
