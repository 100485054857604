import React from 'react';
import { Alert, AlertTitle, Box, List, ListItem, Typography } from '@mui/material';
import GeneralInfo from '../../UiParts/GeneralInfo';
import { updateFormValuesAC } from 'features/QuikSpec/Utils/QuikspecUpdateFormValuesAC';
import { acControlKits, acLicenses, acReaderKits, acSystemConstraints, acHidden } from '../../../Types/ACTypes';
import QuikspecCategoryTabs from '../../UiParts/QuikspecCategoryTabs';
import QuikspecCategoryAcControllers from '../../UiParts/QuikspecCategoryAcControllers';
import QuikspecCategoryAcLicenses from '../../UiParts/QuikspecCategoryAcLicenses';
import { Form, Formik } from 'formik';
import QuikspecSystemConstraints from '../../UiParts/QuikspecSystemConstraints';
import QuikspecSubmitButton from '../../UiParts/QuikspecSubmitButton';
import { useNavigate, useLocation } from 'react-router-dom';
import QuikspecNote from 'features/QuikSpec/Components/UiParts/QuikspecNote';
import { FormValues } from '../../../Types/QuikspecFormTypes';
import getInitialFormValues from 'features/QuikSpec/Utils/QuikspecInitialFormValues';
import { createQuikSpecAndNavigate, updateQuikspecAndNavigate } from '../../../Utils/QuikspecActionAndNavigate';

import useQuikSpecFormValidationSchema from 'features/QuikSpec/Hooks/useQuikSpecFormValidationSchema';
import { useTranslation } from 'react-i18next';
import { useQuikspecFeaturedSystems } from 'features/QuikSpec/Hooks/quikSpecDevices';
import useC2 from 'features/QuikSpec/Hooks/useC2';

const QuikspecAC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const FeaturedSystems = useQuikspecFeaturedSystems();

  const location = useLocation();
  const state = location.state || {};
  const siteName: string = location.state.siteName as string;
  const siteIndex = FeaturedSystems.findIndex((system) => system.siteName === siteName);
  const systemName = FeaturedSystems[siteIndex].seriesName;
  const systemforC2 = FeaturedSystems[siteIndex].systemforC2;

  const categories = [...acControlKits, ...acLicenses, ...acReaderKits, ...acHidden];

  const stateFormValues = state && Object.keys(state).length > 1 ? state.formValues : undefined;
  const initialValues = getInitialFormValues(systemName, categories, stateFormValues, systemforC2);
  const alertError = t('Error');
  const { ac } = useQuikSpecFormValidationSchema();
  const { C2ApiFailed } = useC2();

  const handleSubmit = async (values: FormValues, actions: any) => {
    try {
      actions.setSubmitting(true);
      values = updateFormValuesAC(values);

      //determine if form is in edit mode
      const editing = stateFormValues != undefined;
      if (editing) {
        //update QuikSpec and navigate to QuikSpec Summary page
        await updateQuikspecAndNavigate(values, actions, navigate);
        actions.setSubmitting(false);
        return;
      } else {
        //create QuikSpec and navigate to QuikSpec Summary page
        await createQuikSpecAndNavigate(values, actions, navigate);

        actions.setSubmitting(false);
      }
    } catch (error) {
      actions.setSubmitting(false);
      actions.setErrors({ submit: t('Something_went_wrong_try_again') });
    }
  };

  return (
    <Box>
      {C2ApiFailed && (
        <Alert severity="warning">
          <AlertTitle>{t('Missing')}</AlertTitle>
          Some required C2 contact details are missing. Please try logging in again.
        </Alert>
      )}
      <Formik initialValues={initialValues} validationSchema={ac} onSubmit={handleSubmit}>
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <GeneralInfo editing={state.formValues != undefined} />
              <QuikspecSystemConstraints systemConstraints={acSystemConstraints} />
              <QuikspecCategoryAcControllers categories={acControlKits} />
              <QuikspecCategoryAcLicenses categories={acLicenses} />
              <QuikspecCategoryTabs categories={acReaderKits} />
              {(stateFormValues
                ? Object.keys(errors).length > 0
                : Object.keys(touched).length > 0 && Object.keys(errors).length > 0) && (
                <Alert severity="error">
                  <AlertTitle>{alertError}</AlertTitle>
                  <List>
                    {Object.keys(errors).map((field) => (
                      <ListItem key={field}>
                        <Typography variant="body2" color="error">
                          {errors[field]}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </Alert>
              )}
              <QuikspecSubmitButton
                editing={state.formValues != undefined}
                errors={errors}
                submitting={isSubmitting}
                C2ApiFailed={C2ApiFailed}
              />
              <QuikspecNote systemName={initialValues.systemName} />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default QuikspecAC;
