import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik';
import { registerUser } from 'shared/api/Aws/authApi';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { CA, US } from 'country-flag-icons/react/3x2';
import { LoadingButton } from '@mui/lab';
import { PRIVACY_URL, TERMS_URL } from '../constants';
import { AuthContext } from 'context/AuthContext';
import { getCognitoErrorMessage } from '../utils/cognitoOperations';
import { isApiError } from 'shared/api/ApiError';
import { checkAndSetupC2Contact } from '../utils/c2Operations';
import { EnumList, fetchEnumList, ICountryValue, IStateValue } from 'shared/utils/EnumUtils';
import { useTranslation } from 'react-i18next';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { convertPhoneNumberToE164Format, formatPhoneNumber } from 'shared/utils/helperFunctions';
import useAuthValidationSchemas from 'shared/utils/ValidationSchema/features/Auth/Auth';
import ZipPostalCodeField from 'shared/components/forms/fields/ZipPostalCodeField';

interface IUserBase {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName: string;
  address: string;
  address2: string;
  city: string;
  zipCode: string;
  password: string;
  passwordConfirm: string;
}

interface IUserForm extends IUserBase {
  countryId: string;
  stateId: string;
}

export interface IC2User extends IUserBase {
  state: string;
  country: string;
}

const initialValues: IUserForm = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  companyName: '',
  countryId: '',
  address: '',
  address2: '',
  city: '',
  stateId: '',
  zipCode: '',
  password: '',
  passwordConfirm: ''
};

const isValidE164PhoneNumber = (phoneNumber: string) => {
  // Define an E.164 regex pattern
  const e164Regex = /^\+?[1-9]\d{1,14}$/;

  // Test the phone number against the regex
  return e164Regex.test(phoneNumber);
};

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const { setUsername, setPassword } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasMinChar, setHasMinChar] = useState(false);
  const [hasMatchingPassword, setHasMatchingPassword] = useState(false);
  const [terms, setTerms] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [enumList, setEnumList] = useState<EnumList>({ country: {}, state: {} });
  const [fetchingEnums, setFetchingEnums] = useState(true);
  const { registerValidation } = useAuthValidationSchemas();
  const { t } = useTranslation();

  // Password Match requirement is always satisfied at last
  useEffect(() => {
    finalCheckForm(terms);
  }, [hasMatchingPassword]);

  useEffect(() => {
    fetchEnumList().then((data) => {
      setEnumList(data);
      setFetchingEnums(false);
    });
  }, []);

  /*
   * Run function to get C2 data.
   * Make call to backend save user in Cognito and RDS.
   * */
  const handleRegistration = async (values: IUserForm, { setSubmitting }: FormikHelpers<IUserForm>) => {
    const formattedEmail = values.email.toLowerCase();

    if (!values.phoneNumber || values.phoneNumber.length < 10) {
      setErrorMessage(t('Invalid_phone_number'));
      return;
    }

    const userPhoneNumber = convertPhoneNumberToE164Format(values.phoneNumber);

    if (!isValidE164PhoneNumber(userPhoneNumber)) {
      setErrorMessage(t('Invalid_phone_number'));
      return;
    }

    try {
      /*C2 processes*/
      const c2Payload: IC2User = {
        ...values,
        state: (enumList.state[values.stateId] as IStateValue).isoCode,
        country: (enumList.country[values.countryId] as ICountryValue).alpha3Code
      };
      try {
        await checkAndSetupC2Contact(c2Payload);
      } catch (error: unknown) {
        setErrorMessage(t('Error_setting_c2_user'));
        return;
      }

      /*Cognito and RDS processes*/
      const registrationPayload = {
        userData: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: formattedEmail,
          password: values.password,
          phoneNumber: userPhoneNumber,
          language: values.countryId ? (enumList.country[values.countryId] as ICountryValue).defaultLanguageId : 2,
          countryId: parseInt(values.countryId),
          phoneExtension: '+1'
        }
      };
      await registerUser(registrationPayload);

      setUsername(formattedEmail);
      setPassword(values.password);
      setSubmitting(false);
      navigate('/auth/confirm-account');
    } catch (error: unknown) {
      if (isApiError(error)) {
        const errorMessages = getCognitoErrorMessage(error.code);
        setErrorMessage(errorMessages);
      } else {
        setErrorMessage(t('An_error_registering'));
      }
      return;
    }
  };

  const handleValidPassword = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const targetID = target.id;

    if (targetID === 'password') {
      const passwordCheck = document.getElementById('passwordConfirm') as HTMLInputElement | null;

      if (passwordCheck !== null) {
        const hasLowerCase = /[a-z]/.test(target.value);
        const hasUpperCase = /[A-Z]/.test(target.value);
        const hasNumber = /\d/.test(target.value);
        const hasSpecialChar = /[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/.test(target.value);
        const hasMinChar = target.value.length >= 8;
        const hasMatchingPassword = target.value === passwordCheck.value;

        setHasLowerCase(hasLowerCase);
        setHasUpperCase(hasUpperCase);
        setHasNumber(hasNumber);
        setHasSpecialChar(hasSpecialChar);
        setHasMinChar(hasMinChar);
        setHasMatchingPassword(hasMatchingPassword);
      }
    }
  };

  const handlePasswordMatch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const passwordCheck = document.getElementById('password') as HTMLInputElement | null;

    if (passwordCheck !== null) {
      const hasMatchingPassword = target.value === passwordCheck.value;
      setHasMatchingPassword(hasMatchingPassword);
    }
  };

  const handleTerms = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerms(event.target.checked);
    finalCheckForm(event.target.checked);
  };

  //check the condition for the register button to be enabled
  const finalCheckForm = (checked: boolean) => {
    if (checked && hasLowerCase && hasUpperCase && hasNumber && hasSpecialChar && hasMinChar && hasMatchingPassword) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };

  const handleClickShowPassword = (kind: 'normal' | 'confirm') => {
    if (kind === 'normal') {
      setShowPassword(!showPassword);
    } else {
      setShowPasswordConfirm(!showPasswordConfirm);
    }
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const getStateList = (countryId: string) => {
    const stateList = Object.keys(enumList.state)
      .map((key) => {
        const stateWalker = enumList.state[key] as IStateValue;
        // If the state is not in the selected country, do not add the option
        // Also do not include the unknown option
        if (stateWalker.countryId.toString() !== countryId || stateWalker.value === 'Unknown') {
          return null;
        }

        return (
          <MenuItem key={stateWalker.value} value={key}>
            {stateWalker.value}
          </MenuItem>
        );
      })
      .filter((val) => val !== null);

    if (stateList.length === 0) {
      stateList.push(
        <MenuItem key={t('Select_Country')} value="" disabled={true}>
          {t('Select_Country')}
        </MenuItem>
      );
    }

    return stateList;
  };

  return (
    <Box>
      <SnackbarAlert
        type="error"
        time={10000}
        text={`${errorMessage}`}
        isOpen={!!errorMessage}
        onClose={() => setErrorMessage(null)}
      />
      {fetchingEnums ? (
        <CircularProgress />
      ) : (
        <Formik initialValues={initialValues} validationSchema={registerValidation} onSubmit={handleRegistration}>
          {({ errors, touched, handleChange, isSubmitting, values, setFieldValue }) => (
            <Form>
              <Grid container spacing={1}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="firstName"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('First_Name')}
                    size="small"
                    helperText={touched.firstName && errors.firstName ? errors.firstName : ''}
                    error={touched.firstName && !!errors.firstName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="lastName"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('Last_Name')}
                    size="small"
                    helperText={touched.lastName && errors.lastName ? errors.lastName : ''}
                    error={touched.lastName && !!errors.lastName}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="email"
                    as={TextField}
                    sx={styles.inputField}
                    type="email"
                    label={t('Email_Address')}
                    size="small"
                    helperText={touched.email && errors.email ? errors.email : ''}
                    error={touched.email && !!errors.email}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Field
                    name="phoneNumber"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('Phone_Number')}
                    size="small"
                    helperText={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ''}
                    error={touched.phoneNumber && !!errors.phoneNumber}
                    onFocus={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value === '' || e.target.value === '+') {
                        await setFieldValue('phoneNumber', '+1');
                      }
                    }}
                    onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                      const { value } = e.target;
                      if (value === '' || value === '+') {
                        await setFieldValue('phoneNumber', '+');
                      } else if (value.startsWith('+1') && value.replace(/\D/g, '').length <= 11) {
                        const formattedValue = formatPhoneNumber(value);
                        await setFieldValue('phoneNumber', formattedValue);
                      }
                      // // FIX: RM-908 for restricting phone number to 15 characters
                      // if (/^\+?\d*$/.test(value) && value.length <= 15) {
                      //   handleChange(e);
                      // }
                    }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="companyName"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('Company_Name')}
                    size="small"
                    helperText={touched.companyName && errors.companyName ? errors.companyName : ''}
                    error={touched.companyName && !!errors.companyName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s.]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="address"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('Address')}
                    size="small"
                    helperText={touched.address && errors.address ? errors.address : ''}
                    error={touched.address && !!errors.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s.]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="address2"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('Address_2')}
                    size="small"
                    helperText={touched.address2 && errors.address2 ? errors.address2 : ''}
                    error={touched.address2 && !!errors.address2}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormControl
                    sx={styles.inputField}
                    size="small"
                    error={touched.countryId && Boolean(errors.countryId)}
                  >
                    <InputLabel id="country-label">{t('Select_Country')}</InputLabel>
                    <Field
                      name="countryId"
                      id="countryId"
                      as={Select}
                      labelId="country-label"
                      label={t('Select_Country')}
                      onChange={async (e: any) => {
                        handleChange(e);
                        // Reset/clear the city, state, and zip code because we have new formatting standards
                        await setFieldValue('stateId', '');
                        await setFieldValue('city', '');
                        await setFieldValue('zipCode', '');
                      }}
                    >
                      {Object.keys(enumList.country).map((key) => {
                        const validCountries = ['CA', 'US'];
                        const countryWalker = enumList.country[key] as ICountryValue;

                        if (!validCountries.includes(countryWalker.alpha2Code)) {
                          return null;
                        }

                        const flagComponent: JSX.Element =
                          countryWalker.alpha2Code === 'CA' ? (
                            <CA title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                          ) : (
                            <US title={countryWalker.defaultLanguageName} style={styles.flagStyle} />
                          );

                        return (
                          <MenuItem key={key} value={key}>
                            <Grid sx={styles.field}>
                              {flagComponent}
                              {countryWalker.value}
                            </Grid>
                          </MenuItem>
                        );
                      })}
                    </Field>
                    <Box sx={styles.errorMessage}>
                      <ErrorMessage name="country" />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FormControl sx={styles.inputField} size="small" error={touched.stateId && Boolean(errors.stateId)}>
                    <InputLabel id="state-label">{t('Select_State')}</InputLabel>
                    <Field
                      name="stateId"
                      id="stateId"
                      as={Select}
                      labelId="state-label"
                      disabled={!['2', '3'].includes(values.countryId)}
                      label={t('Select_State')}
                    >
                      {getStateList(values.countryId)}
                    </Field>
                    <Box sx={styles.errorMessage}>
                      <ErrorMessage name="state" />
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Field
                    onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (!e.key.match(/^[a-zA-Z\s]*$/)) {
                        e.preventDefault();
                      }
                    }}
                    name="city"
                    as={TextField}
                    sx={styles.inputField}
                    label={t('City')}
                    disabled={!['2', '3'].includes(values.countryId)}
                    size="small"
                    helperText={touched.city && errors.city ? errors.city : ''}
                    error={touched.city && !!errors.city}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <Field
                    name="zipCode"
                    component={ZipPostalCodeField}
                    sx={styles.inputField}
                    disabled={!['2', '3'].includes(values.countryId)}
                    maxLength={values.countryId === '2' ? 5 : 7}
                    size="small"
                    placeholder={values.countryId === '2' ? t('Organization_ZipCode') : t('PostalCode')}
                    helperText={touched.zipCode && errors.zipCode ? errors.zipCode : ''}
                    error={touched.zipCode && !!errors.zipCode}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    name="password"
                    as={TextField}
                    sx={styles.inputField}
                    id="password"
                    label={t('Password')}
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position={'end'}>
                        <IconButton
                          aria-label={'toggle password visibility'}
                          onClick={() => handleClickShowPassword('normal')}
                          onMouseDown={handleMouseDownPassword}
                          edge={'end'}
                          tabIndex={-1}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    helperText={touched.password && errors.password ? errors.password : ''}
                    error={touched.password && !!errors.password}
                    onKeyUp={handleValidPassword}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    name="passwordConfirm"
                    as={TextField}
                    sx={styles.inputField}
                    id="passwordConfirm"
                    label={t('Confirm_Password')}
                    size="small"
                    type={showPasswordConfirm ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position={'end'}>
                        <IconButton
                          aria-label={'toggle password visibility'}
                          onClick={() => handleClickShowPassword('confirm')}
                          onMouseDown={handleMouseDownPassword}
                          edge={'end'}
                          tabIndex={-1}
                        >
                          {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    helperText={touched.passwordConfirm && errors.passwordConfirm ? errors.passwordConfirm : ''}
                    error={touched.passwordConfirm && !!errors.passwordConfirm}
                    onKeyUp={handlePasswordMatch}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={0}>
                <Grid item xs={6}>
                  <Typography id="lowerCase" sx={hasLowerCase ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_OneLowerCaseLetter')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography id="upperCase" sx={hasUpperCase ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_OneUpperCaseLetter')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography id="number" sx={hasNumber ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_OneNumber')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography id="specialChar" sx={hasSpecialChar ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_OneSpecialCharacter')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography id="minLength" sx={hasMinChar ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_EightCharacters')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography id="match" sx={hasMatchingPassword ? styles.valid : styles.invalid}>
                    &#x2022; {t('PasswordCriteria_PasswordMatch')}
                  </Typography>
                </Grid>
              </Grid>

              <Box sx={{ mt: 3 }}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Checkbox checked={terms} sx={styles.termCheckbox} onChange={(e) => handleTerms(e)} />
                  </Grid>
                  <Grid item sm={6} xs={10}>
                    <Typography variant="body1" sx={styles.registerText}>
                      {t('Clicking_RegisterDescription')}{' '}
                      <Box component={Link} to={TERMS_URL} sx={styles.link} target="_blank">
                        {t('Terms_And_Conditions')}
                      </Box>
                      {' and '}
                      <Box component={Link} to={PRIVACY_URL} sx={styles.link} target="_blank">
                        {t('PrivacyPolicy')}
                      </Box>
                      .
                    </Typography>
                  </Grid>
                  <Grid item md={5} sm={4} xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="aiphoneOrange"
                      sx={styles.registerButton}
                      disabled={
                        isButtonDisabled ||
                        !!errors.password ||
                        !!errors.phoneNumber ||
                        !!errors.address ||
                        !!errors.zipCode ||
                        !!errors.email ||
                        !!errors.firstName ||
                        !!errors.lastName ||
                        !!errors.city ||
                        !!errors.stateId ||
                        !!errors.companyName
                      }
                      loading={isSubmitting}
                      loadingIndicator={<CircularProgress size="20px" color="white" />}
                    >
                      {t('Button_CloudAccount_Register')}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Box>

              <Grid container sx={styles.haveAccountContainer}>
                <Grid item>
                  <Typography variant="body1" sx={styles.registerText}>
                    {t('CloudRegistration_AlreadyHaveAnAccount')}{' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button sx={styles.signInText} onClick={() => navigate('/auth/login')}>
                    {t('CloudRegistration_SignIn')}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default Registration;

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  inputField: {
    marginBottom: 1,
    width: '100%',
    '& .MuiInputBase-input': {
      backgroundColor: '#ffffff'
    },
    '&.MuiFormHelperText-root': {
      color: 'red'
    },
    '& .MuiInputLabel-root': {
      color: 'red',
      '&.Mui-focused': {
        color: 'black'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: '#003366'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0071ce'
      }
    }
  },
  field: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  flagStyle: {
    width: '33px',
    minWidth: '33px',
    height: '22px',
    paddingRight: '10px'
  },
  radioGroup: {
    display: 'flex',
    width: '800px',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  userRole: {
    border: 1,
    borderColor: '#e7e7e7',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  checkUserRole: {
    border: 1,
    borderColor: '#0071ce',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    color: '#0071ce',
    marginRight: '10px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  radio: {
    '&.Mui-checked': {
      color: '#0071ce'
    }
  },
  invalid: {
    color: '#d32f2f',
    fontSize: '0.8rem'
  },
  valid: {
    color: '#00BB31',
    fontSize: '0.8rem'
  },
  registerButton: {
    fontSize: '1.5rem',
    fontWeight: '700',
    borderRadius: '0.25 rem',
    width: '100%',
    '&:disabled': {
      backgroundColor: '#d24800',
      color: 'white',
      opacity: 0.5
    }
  },
  registerText: {
    color: '#003366',
    float: 'right',
    fontFamily: "'Roboto Condensed', sans-serif"
  },
  signInText: {
    color: '#0071CE',
    fontFamily: "'Roboto Condensed', sans-serif",
    fontWeight: 'bold'
  },
  termCheckbox: {
    top: -8
  },
  link: {
    textDecoration: 'none',
    color: '#0071CE',
    fontWeight: 'bold'
  },
  errorMessage: {
    color: '#d32f2f',
    fontSize: '0.75rem',
    margin: 0
  },
  haveAccountContainer: {
    alignItems: 'center',
    justifyContent: 'end',
    paddingBottom: 2
  }
};
