// NOTE: Only Lift Control devices have the delete buttons disabled.
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteDeviceMutation,
  useHandleGatewayCommandMutation,
  useUpdateDeviceMutation
} from 'services/aiphoneCloud';
import { updateSite } from 'shared/api/Aws/RemoteManagementApi';
import SnackbarAlert from 'shared/components/alerts/SnackbarAlert';
import { gwCommand } from 'shared/rmGateway/gwCommand';
import { fetchGatewayCommand } from 'shared/rmGateway/gwCommandProcessor';
import { RootState } from 'store';
import { getSelectedDevice } from 'store/slices/devicesSlice';
import { getSite } from 'store/slices/siteSlice';
import { updateSite as updateSiteInStore } from 'store';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

interface DialogProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onConfirm: () => void;
  isLoading: boolean;
}

enum DialogMode {
  initializeAndDelete,
  delete
}

const DeleteDeviceDialog = ({ isOpen, setIsOpen, onConfirm, isLoading }: DialogProps) => {
  return (
    <>
      {' '}
      <Box>
        <Dialog open={isOpen}>
          <DialogTitle> {t('Device_Settings.Maintenance.Delete_Device_Section.Dialog.Title')} </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {' '}
              {t('Device_Settings.Maintenance.Delete_Device_Section.Dialog.Description')}{' '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isLoading}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {' '}
              {t('Device_Settings.Maintenance.Delete_Device_Section.Dialog.Button_Titles.Cancel')}{' '}
            </Button>
            <Button
              disabled={isLoading}
              onClick={() => {
                onConfirm();
              }}
            >
              {' '}
              {t('Device_Settings.Maintenance.Delete_Device_Section.Dialog.Button_Titles.Delete')}{' '}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

const DeleteDeviceSection = ({ hasEditPermission }: { hasEditPermission: boolean }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedDevice = useSelector(getSelectedDevice);
  const deviceId = selectedDevice?.publicId;
  const [deleteDevice] = useDeleteDeviceMutation();
  const [handleGatewayCommand] = useHandleGatewayCommandMutation();
  const [updateDevice] = useUpdateDeviceMutation();
  const navigate = useNavigate();
  const sitePublicId = useParams().id;
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMode, setDialogMode] = useState<DialogMode>(DialogMode.initializeAndDelete);
  const site = useSelector(getSite);
  const gateway = useSelector(
    (state: RootState) => state.devices.DeviceList[site?.siteInfo?.registeredGatewayPublicId]
  );
  const isGatewayFirstSync = gateway?.lastSyncedOn === null;
  const defaultCredential = 'admin';

  const currentAction = (mode: DialogMode) => {
    switch (mode) {
      case DialogMode.initializeAndDelete:
        return handleInitializeAndDelete;
      case DialogMode.delete:
        return handleDelete;
    }
  };

  async function initializeDevice() {
    if (!gateway) throw new Error('Gateway not registered, cannot be initialized');
    //if (!gateway?.basicInfo?.macAddress) throw new Error('Missing Gateway Mac Address');

    // setErrorMessage if user did not have Edit permission
    if (!hasEditPermission) {
      throw new Error('You have view-only access to this site.');
    }
    const systemId = site?.siteInfo?.systemId;
    const systemPassword = site?.siteInfo?.systemPassword;
    const gatewayInfo = {
      awsPropertyId: site?.siteInfo?.awsPropertyId,
      gwMacAddress: gateway?.basicInfo?.macAddress,
      gwId: systemId ? systemId : isGatewayFirstSync ? defaultCredential : gateway?.basicInfo.adminId,
      gwPassword: systemPassword
        ? systemPassword
        : isGatewayFirstSync
        ? defaultCredential
        : gateway?.basicInfo.adminPass,
      gwIpAddress: gateway?.networkSettings?.ipV4Address
    };
    const isDeviceFirstSync = selectedDevice?.lastSyncedOn === null;

    const deviceInfo = {
      deviceMacAddress: selectedDevice?.basicInfo?.macAddress,
      deviceIpAddress: selectedDevice?.networkSettings?.ipV4Address,
      deviceType: selectedDevice?.basicInfo?.deviceType,
      deviceId: systemId ? systemId : isDeviceFirstSync ? defaultCredential : selectedDevice?.basicInfo.adminId,
      devicePassword: systemPassword
        ? systemPassword
        : isDeviceFirstSync
        ? defaultCredential
        : selectedDevice?.basicInfo.adminPass
    };

    try {
      // send command to gateway via ioT
      const ioTPayload = fetchGatewayCommand('sendCommand', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      if (ioTPayload === undefined) throw new Error('IoT Payload undefined');

      await handleGatewayCommand(ioTPayload).unwrap();
      // create a timeout for 60 seconds
      await new Promise((resolve) => setTimeout(resolve, 60000));

      // fetch the result from the gateway
      const fetchPayload = fetchGatewayCommand('fetchResult', gwCommand.INITIALIZE, gatewayInfo, deviceInfo, null);
      const fetchResponse = await handleGatewayCommand(fetchPayload).unwrap();

      const statusCode = fetchResponse?.statusCode.slice(0, 3);
      setIsLoading(false);
      if (statusCode !== '200') {
        setErrorMessage(t('Unable_to_initialize_device', { statusCode }));
      }

      const updatedDeviceParams = {
        device: {
          publicId: selectedDevice?.publicId
        }
      };
      updateDevice(updatedDeviceParams);

      if (selectedDevice?.basicInfo.deviceType === 18) {
        const updateSitePayload = {
          updatedSite: {
            publicId: site.siteInfo.publicId,
            registeredGatewayPublicId: null
          }
        };
        dispatch(updateSiteInStore(updateSitePayload));
        await updateSite(updateSitePayload);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(`${error}`);
    }
  }

  function handleDeleteButtonClick() {
    setDialogMode(DialogMode.delete);
    setIsDialogOpen(true);
  }

  function handleInitDeleteButtonClick() {
    setDialogMode(DialogMode.initializeAndDelete);
    setIsDialogOpen(true);
  }

  async function handleInitializeAndDelete() {
    setIsDialogOpen(false);
    try {
      await initializeDevice();
      await handleDelete();
    } catch (error) {
      setError(true);
      setErrorMessage(`${error}`);
      setIsLoading(false);
    }
  }

  async function handleDelete() {
    if (deviceId === undefined) {
      setError(true);
      setErrorMessage(t('Could_not_find_device_id'));
      return;
    }

    if (deviceId !== undefined) {
      try {
        setIsDialogOpen(false);
        setIsLoading(true);
        await deleteDevice(deviceId);
        setIsLoading(false);
        redirectToSite();
      } catch (error) {
        setError(true);
        setErrorMessage(t('Unable_to_delete_device'));
      }
    }
  }

  function clearErrorState() {
    setError(false);
    setErrorMessage('');
  }

  function redirectToSite() {
    navigate(`/site/${sitePublicId}`);
  }

  return (
    <>
      <SnackbarAlert type="error" text={errorMessage} time={5000} isOpen={error} onClose={clearErrorState} />
      <DeleteDeviceDialog
        isOpen={isDialogOpen}
        setIsOpen={setIsDialogOpen}
        onConfirm={currentAction(dialogMode)}
        isLoading={isLoading}
      />
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ paddingTop: styles.elementPadding, paddingBottom: styles.elementPadding }}>
        <Typography variant="h5" color="text.primary" sx={{ paddingBottom: styles.elementPadding }}>
          {t('Device_Settings.Maintenance.Delete_Device_Section.Section_Title')}
        </Typography>
        <Typography color="text.primary" sx={{ paddingBottom: styles.elementPadding }}>
          {t('Device_Settings.Maintenance.Delete_Device_Section.Section_Description')}
        </Typography>
        <Stack direction="column" sx={styles.container}>
          <Stack alignItems={'center'} direction="row" sx={{ padding: styles.rowPadding }}>
            <LoadingButton
              loading={isLoading}
              disabled={isLoading || !hasEditPermission}
              loadingIndicator={<CircularProgress size={styles.buttonSize} color="info" />}
            >
              <DeleteIcon
                onClick={() => {
                  handleInitDeleteButtonClick();
                }}
              />
            </LoadingButton>
            <Typography variant="h6">
              {t('Device_Settings.Maintenance.Delete_Device_Section.Button_Titles.Initialize_And_Delete')}
            </Typography>
          </Stack>

          <Stack alignItems={'center'} direction="row" sx={{ padding: styles.rowPadding }}>
            <LoadingButton
              loading={isLoading}
              loadingIndicator={<CircularProgress size={styles.buttonSize} color="info" />}
              onClick={() => {
                handleDeleteButtonClick();
              }}
              disabled={isLoading || !hasEditPermission}
            >
              <DeleteForeverIcon />
            </LoadingButton>
            <Typography variant="h6">
              {t('Device_Settings.Maintenance.Delete_Device_Section.Button_Titles.Delete_Device')}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

const defaultPadding = '1rem';
const styles = {
  container: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: defaultPadding
  },
  rowPadding: defaultPadding,
  elementPadding: '0.5rem',
  buttonSize: '1.25rem'
};

export default DeleteDeviceSection;
